<template>
  <div class="member d-lg-flex">
    <div class="m-left">
      <div class="info">
        <img :src="$store.state.userinfo.avatar" alt="" />
        <p>{{ $store.state.userinfo.username }}</p>
      </div>
      <div class="opitons-block">
        <div class="options">
          <section class="d-flex">
            <img @click="pick" src="~assets/images/icon/sub.png" alt="" />
            <p>Order Center</p>
          </section>
          <div class="option" v-if="this.options0">
            <router-link :to="{ name: 'MyOrder' }" class="cur-option" tag="div"
              >My Order</router-link
            >
            <router-link
              :to="{ name: 'ReturnOrder' }"
              class="cur-option"
              tag="div"
              >Retrun Order</router-link
            >
          </div>
        </div>
        <div class="options">
          <section class="d-flex">
            <img @click="pick1" src="~assets/images/icon/sub.png" alt="" />
            <p>Member Center</p>
          </section>
          <div class="option" v-if="this.options1">
            <router-link :to="{ name: 'UserInfo' }" class="cur-option" tag="div"
              >User information</router-link
            >
            <router-link :to="{ name: 'Security' }" class="cur-option" tag="div"
              >Account security</router-link
            >
          </div>
        </div>

        <div class="logout">
          <span @click="logout">logout</span>
        </div>
      </div>
    </div>

    <div class="m-right">
      <router-view />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { user } from "network/login";
import { logout } from "network/user";
import Cookie from "cookie_js";
export default {
  name: "Member",
  data() {
    return {
      options0: true,
      options1: true,
    };
  },
  created() {
    this.$router.push("/member");
  },
  methods: {
    pick() {
      this.options0 = !this.options0;
    },
    pick1() {
      this.options1 = !this.options1;
    },

    logout() {
      logout().then((res) => {
        if (res.data.code == 1) {
          //this.$store.commit("loginStausF");
          //Cookie.remove(["token", "login"]);
          this.$store.dispatch("setAccount", {});
          this.$router.push("/signIn");
        }
      });
    },
    //请求会员信息

    //请求购物车列表
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.member {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
.m-left {
  padding: 0;
  width: 5rem;
}
.info {
  text-align: center;

  background: #ffecd9;
}
.info img {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0.5rem;
}
.info p {
  padding: 0.5rem 0;
  color: #000000;
  font-size: 0.4rem;
}
.opitons-block {
  padding-bottom: 0.5rem;
}
.options {
  padding: 0 0.25rem;
}
.options section {
  font-size: 0.45rem;
  color: #4c4c4c;
  align-items: center;
  margin-top: 0.5rem;
}
.options section img {
  width: 20px;
  height: 20px;
  margin-right: 0.2rem;
}
.option {
  font-size: 0.35rem;
  margin-left: 0.8rem;
}
.cur-option {
  color: #4c4c4c;
  margin-top: 0.35rem;
  cursor: pointer;
}
.cur1-option {
  color: #ff7f00;
  margin-top: 0.35rem;
  cursor: pointer;
}
.m-right {
  padding: 0 0.5rem;
  width: 17.5rem;
}
.logout {
  text-align: center;
}
.logout span {
  background: #ff7f00;
  font-size: 0.4rem;
  color: #fff;
  align-items: center;
  margin-top: 0.5rem;
  padding: 0.2rem 0.25rem;
  border-radius: 0.15rem;
  cursor: pointer;
}
@media (max-width: 992px) {
  .m-left {
    padding: 0 0.5rem;
    width: 100%;
  }
  .m-right {
    padding: 0 0.5rem;
    width: 100%;
  }
}
</style>
